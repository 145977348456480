






















import { Component, Vue } from 'vue-property-decorator'
import { getMessages } from '@/services/open-live-client'
import { LiveMessage } from '@/components/model'
import Spinner from '@/components/Spinner.vue'
import { formatDistanceToNowGerman } from '@/components/util/datefns-util'

@Component({
  components: { Spinner }
})
export default class Feed extends Vue {
  private messages: LiveMessage[] = []

  mounted() {
    this.refreshFeed()
    setInterval(this.refreshFeed, 1000)
  }

  private formatTimestamp(timestamp: string) {
    return formatDistanceToNowGerman(new Date(timestamp))
  }

  private refreshFeed() {
    getMessages(this.$route.params.feed).then(res => {
      // NOTE: always polling everything also refreshes the timestamp
      this.messages = res.data
    })
  }
}
