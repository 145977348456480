import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Feed from '@/views/Feed.vue'
import Landing from '@/views/Landing.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/:feed',
    name: 'Feed',
    component: Feed
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
