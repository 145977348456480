import axios, { AxiosInstance } from 'axios'

export const http: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-type': 'application/json'
  }
})

/*
const authInterceptor = (request) => {
  const jwt = localStorage.getItem(JWT_KEY)
  if (jwt) {
    request.headers.Authorization = 'Bearer ' + jwt
  }
  return request
}

http.interceptors.request.use(authInterceptor)
 */
