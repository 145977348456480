































import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Landing extends Vue {
  private code = ''

  private openFeed() {
    if (this.code) {
      this.$router.push(`/${this.code}`)
    }
  }
}
